import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from "src/templates"

const HeroSmall = ({title, body, callToAction, backgroundDesktop, backgroundMobile, backgroundColor = null, variant = 1}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  backgroundMediaSmall: {
   marginTop: "99px",
   marginBottom: variant === "2" ? "0px" : "40px",
   objectFit: "cover",
   background: backgroundColor && backgroundColor,
   width: "100%",
   position: "relative",
   top: 0,
   left: 0,
   minHeight: 526,
   [theme.breakpoints.down('lg')]: {
    opacity: 0.10,
    height: "calc(50vh - 99px)",
    minHeight: "calc(50vh - 99px)",
   }
  },
  backgroundContentSmall: {
   width: "100%",
   display: 'flex',
   justifyContent: "flex-end",
   alignItems: "center",
   position: "absolute",
   top: 0,
   padding: "0px 6.25%",
   maxWidth: "1920px",
   height: "35vw",
   minHeight: 526,
   [theme.breakpoints.down('lg')]: {
    left: 0,
    position: "relative",
    top: 'auto',
    height: "fit-content",
    flexDirection: 'column',
    padding: "0px",
    minHeight: "calc(50vh - 99px)",
   }
  },
  backgroundContentTitleSmall: {
   [theme.breakpoints.down('lg')]: {
    top: 0,
    position: "absolute",
    width: "100%",
    marginTop: "99px",
    height: "calc(50vh - 99px)",
    flexDirection: 'column',
    padding: "25px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
   }
  },
 }
 
 return (
  <>
   {!lg ? ( 
    backgroundDesktop && (
     <Box component="img" alt="hero-desktop" src={backgroundDesktop} sx={styles.backgroundMediaSmall}/>
    )
   ) : (
    backgroundMobile && (
     <Box component="img" alt="hero-mobile" src={backgroundMobile} sx={styles.backgroundMediaSmall}/>
    )
   )}
   {!lg ? (
    <div style={{display: "flex", justifyContent: "center"}}>
     <Box sx={styles.backgroundContentSmall}>
      <Box sx={{width: '50%', marginTop: variant === "2" ? "225px" : "175px"}}>
       <Typography variant="h1" color= {(variant === 1) ? "primary.main" : "text.secondary"}>{title}</Typography>
       <Typography variant="subtitle1" color= {(variant === 1) ? "primary.dark" : "text.secondary"} sx={{mt: 4, mb: 4}}>{body}</Typography>
       {callToAction && (
        <a href={callToAction[0]?.link} style={{ textDecoration: 'none'}}>
         <Button variant={"contained"}>{callToAction[0]?.text}</Button>
        </a>
       )}
      </Box>
     </Box>
    </div>
   ) : (
    <Box sx={styles.backgroundContentTitleSmall}>
     <Box sx={{width: '100%', textAlign: lg ? "center" : "right", marginRight: lg ? 0 : "5%"}}>
      <Typography variant="h1" color={lg ? "secondary.main" : "secondary.main"}>{title}</Typography>
     </Box>
     <Box sx={{width: '100%', textAlign: lg ? "center" : "right", marginTop: "35px", marginRight: lg ? 0 : "5%"}}>
      <Typography variant="h4" color={lg ? "text.primary" : "secondary.dark"}>{body}</Typography>
     </Box>
    </Box>
   )}
  </>
 )
}

export { HeroSmall }