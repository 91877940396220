export const isCountryChile = () => {
    return typeof window !== "undefined" && window.environmentCountry === "CL";
};

export const isCountryPeru = () => {
    return typeof window !== "undefined" && window.environmentCountry === "PE";
};

export const envCountry = () =>
    typeof window !== "undefined" ? window.environmentCountry : "";
