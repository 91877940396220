exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-cumplo-js": () => import("./../../../src/pages/about-cumplo.js" /* webpackChunkName: "component---src-pages-about-cumplo-js" */),
  "component---src-pages-ayuda-anticipos-js": () => import("./../../../src/pages/ayuda/anticipos.js" /* webpackChunkName: "component---src-pages-ayuda-anticipos-js" */),
  "component---src-pages-ayuda-cavali-y-factrack-js": () => import("./../../../src/pages/ayuda/cavali-y-factrack.js" /* webpackChunkName: "component---src-pages-ayuda-cavali-y-factrack-js" */),
  "component---src-pages-ayuda-gestion-de-pago-js": () => import("./../../../src/pages/ayuda/gestion-de-pago.js" /* webpackChunkName: "component---src-pages-ayuda-gestion-de-pago-js" */),
  "component---src-pages-ayuda-inversiones-js": () => import("./../../../src/pages/ayuda/inversiones.js" /* webpackChunkName: "component---src-pages-ayuda-inversiones-js" */),
  "component---src-pages-ayuda-js": () => import("./../../../src/pages/ayuda.js" /* webpackChunkName: "component---src-pages-ayuda-js" */),
  "component---src-pages-ayuda-saldo-cumplo-js": () => import("./../../../src/pages/ayuda/saldo-cumplo.js" /* webpackChunkName: "component---src-pages-ayuda-saldo-cumplo-js" */),
  "component---src-pages-ayuda-sobre-cumplo-js": () => import("./../../../src/pages/ayuda/sobre-cumplo.js" /* webpackChunkName: "component---src-pages-ayuda-sobre-cumplo-js" */),
  "component---src-pages-corporaciones-js": () => import("./../../../src/pages/corporaciones.js" /* webpackChunkName: "component---src-pages-corporaciones-js" */),
  "component---src-pages-impact-js": () => import("./../../../src/pages/impact.js" /* webpackChunkName: "component---src-pages-impact-js" */),
  "component---src-pages-impacto-js": () => import("./../../../src/pages/impacto.js" /* webpackChunkName: "component---src-pages-impacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inversiones-js": () => import("./../../../src/pages/inversiones.js" /* webpackChunkName: "component---src-pages-inversiones-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-prevencion-de-fraudes-js": () => import("./../../../src/pages/prevencion-de-fraudes.js" /* webpackChunkName: "component---src-pages-prevencion-de-fraudes-js" */),
  "component---src-pages-pymes-js": () => import("./../../../src/pages/pymes.js" /* webpackChunkName: "component---src-pages-pymes-js" */),
  "component---src-pages-recuperar-contrasena-js": () => import("./../../../src/pages/recuperar-contrasena.js" /* webpackChunkName: "component---src-pages-recuperar-contrasena-js" */),
  "component---src-pages-register-form-peru-js": () => import("./../../../src/pages/registerFormPeru.js" /* webpackChunkName: "component---src-pages-register-form-peru-js" */),
  "component---src-pages-simulador-mora-js": () => import("./../../../src/pages/simulador-mora.js" /* webpackChunkName: "component---src-pages-simulador-mora-js" */),
  "component---src-pages-simulator-js": () => import("./../../../src/pages/simulator.js" /* webpackChunkName: "component---src-pages-simulator-js" */),
  "component---src-pages-sobre-cumplo-js": () => import("./../../../src/pages/sobre-cumplo.js" /* webpackChunkName: "component---src-pages-sobre-cumplo-js" */),
  "component---src-pages-terminos-y-regulaciones-js": () => import("./../../../src/pages/terminos-y-regulaciones.js" /* webpackChunkName: "component---src-pages-terminos-y-regulaciones-js" */)
}

