import React from "react"
import "src/assets/styles/global-styles.css";
import { ThemeProvider } from '@mui/material/styles';
import { CumploBranding } from "templates"
import CssBaseline from '@mui/material/CssBaseline';
import { Header } from "src/components/common/Header"
import { Footer } from "src/components/common/Footer"
import Cookies from 'universal-cookie';

const TopLayout = ({ children }) => {
 const cookies = new Cookies();
 if (cookies.get('preventFraudAnnouncement') === undefined) {
    cookies.set('preventFraudAnnouncement', true, { path: '/' })
 }

 return (
  <ThemeProvider theme={CumploBranding}>
   <CssBaseline />
   <Header />
   {children}
   <Footer />
  </ThemeProvider>
 )
}

export { TopLayout }